/* eslint-disable max-lines */
import getBrowserWindow from './window';

const browserWindow = getBrowserWindow();

// check for adrum script injection
function isScriptLoaded() {
  return browserWindow.ADRUM;
}

// success status
function success(msg) {
  return { status: true, message: msg };
}

// error status
function error(msg) {
  return { status: false, message: msg };
}

function bindCustomData(viewName, customData, context) {
  return typeof customData === 'function' && customData(viewName, context);
}

// To Add Custom User Data to a Page Browser Snapshot
// Ref => https://docs.appdynamics.com/display/PRO45/Add+Custom+User+Data+to+a+Page+Browser+Snapshot
export function setCustomUserData(onUserEvent) {
  if (typeof onUserEvent !== 'function')
    return error('Please provide custom user data callback function');
  const config = window['adrum-config'] || {};
  const userEventInfo = {
    PageView: bindCustomData.bind(this, 'pageview', onUserEvent),
    VPageView: bindCustomData.bind(this, 'vpageview', onUserEvent),
    Ajax: bindCustomData.bind(this, 'ajax', onUserEvent),
  };
  config.userEventInfo = userEventInfo;
  window['adrum-config'] = config;
  return success('Custom User Data to a Page Browser Snapshot added');
}

// To Filter XHR Calls by URLs
// https://docs.appdynamics.com/display/PRO45/Filter+XHR+Calls+by+URLs
// Note : To use XHR filters, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script
/*
The general structure for the filter object
params => filterPattern
For ex.
{
  include : {
      urls:
        [
          {
            pattern: ".*foo.*"
          },
          {
            pattern: ".*bar.*"
          }
        ],
      method: 'GET'
  },
  exclude : {
      urls:
        [
          {
            pattern: ".*user-profile.*"
          }
        ],
      method: 'POST'
  }
}
*/
export function setXHRFilter(filterPattern) {
  if (!isScriptLoaded()) {
    return error(
      'To use XHR filters, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script',
    );
  }
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || {};
    if (filterPattern.include) {
      xhr.include = filterPattern.include;
    }
    if (filterPattern.exclude) {
      xhr.exclude = filterPattern.exclude;
    }
    config.xhr = xhr;
    window['adrum-config'] = config;
    return success('Include\\Exclude filter patterns added successfully');
  }
  return error('XHR Include\\Exclude filter patterns are not valid');
}

// To Report Events with the JavaScript API
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
// To report exception/error
// Param => exception
// e.g {type : 'Uncaught Exception', }
export function reportError(exception) {
  const adrum = browserWindow.ADRUM;
  if (adrum) {
    const err = getErrorHandle();
    err.line((exception && exception.line) || 0);
    err.msg((exception && exception.message) || '');
    adrum.report(err);
    return success('Error reported successfully');
  }
  return error("'adrum' is not initialized");
}

// To Report a custom Ajax event passing properties
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
// eslint-disable-next-line complexity
export function reportAjax(reportInfo) {
  const adrum = browserWindow.ADRUM;
  if (adrum) {
    const ajax = getAjaxHandle();
    // set url
    ajax.url((reportInfo && reportInfo.url) || 'NA');
    // mark timings
    ajax.markSendTime((reportInfo && reportInfo.markSendTime) || 0);
    ajax.markFirstByteTime((reportInfo && reportInfo.markFirstByteTime) || 0);
    ajax.markRespAvailTime((reportInfo && reportInfo.markRespAvailTime) || 0);
    ajax.markRespProcTime((reportInfo && reportInfo.markRespProcTime) || 0);
    adrum.report(ajax);
    return success('Ajax reported successfully');
  }
  return error("'adrum' is not initialized");
}

// To Report a custom Ajax/Error/VPageView event passing properties
// Make use of handlers as per need
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
export function report(event) {
  const adrum = browserWindow.ADRUM;
  if (adrum) {
    adrum.report(event);
    return success('Event : Ajax/Error/VPageView reported successfully');
  }
  return error("'adrum' is not initialized");
}

// To get error event handle to set required error log properties
export function getErrorHandle() {
  const adrum = browserWindow.ADRUM;
  return adrum ? new adrum.events.Error() : error("'adrum' is not initialized");
}

// To get ajax event handle to set required ajax log properties
export function getAjaxHandle() {
  const adrum = browserWindow.ADRUM;
  return adrum ? new adrum.events.Ajax() : error("'adrum' is not initialized");
}

// To get virtual page view event handle to set required ajax log properties
export function getVPageViewHandle() {
  const adrum = browserWindow.ADRUM;
  return adrum
    ? new adrum.events.VPageView()
    : error("'adrum' is not initialized");
}

// To Disable Monitoring of Fetch API Calls
// Ref => https://docs.appdynamics.com/display/PRO45/Disable+Monitoring+of+Fetch+API+Calls
export function disableFetchMonitoring(isSPA, isAngular) {
  const config = window['adrum-config'] || {};
  config.fetch = false;
  if (isSPA) {
    const spa = config.spa || {};
    spa.spa2 = true;
    config.spa = spa;
  }
  if (isAngular) {
    config.angular = true;
  }
  window['adrum-config'] = config;
  return success('Fetch API call monitoring is disabled');
}

// To Handle the window.onerror Event
// Ref => https://docs.appdynamics.com/display/PRO45/Handle+the+window.onerror+Event
// Note: If any script on your monitored web pages, including library code, sets the JavaScript window.onerror event,
// add the following method to the page immediately after setting window.onerror
export function reportWindowOnError() {
  if (browserWindow.ADRUM) {
    browserWindow.ADRUM.listenForErrors();
    return success('window.onerror reporting listening mode started');
  }
  return error("'adrum' is not initialized");
}

// To Disable Browser Monitoring Programmatically
// Ref => https://docs.appdynamics.com/display/PRO45/Disable+Browser+Monitoring+Programmatically
// Note : To disable Browser Monitoring add the snippet below before adrum.js agent is injected.
export function disableBrowserMonitoring() {
  window['adrum-disable'] = true;
  return success('Browser monitoring is disabled');
}

// To Enable Browser Monitoring Programmatically
export function enableBrowserMonitoring() {
  window['adrum-disable'] = false;
  return success('Browser monitoring is enabled');
}

// To Set Custom Page Names
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Custom+Page+Names
export function setCustomPageName(userPageName) {
  if (userPageName) {
    const config = window['adrum-config'] || {};
    const userEventInfo = config.userEventInfo || {};
    const pageView = userEventInfo.PageView || {};
    pageView.userPageName = userPageName;
    userEventInfo.PageView = pageView;
    config.userEventInfo = userEventInfo;
    window['adrum-config'] = config;
    return success('Custom page name is set');
  }
  return error('Please provide valid page name');
}

// To Set Custom Virtual Page Names
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Custom+Virtual+Page+Names
// Note : To name virtual pages with the JavaScript Agent, you need to enable SPA2 monitoring.
export function setCustomVirtualPageName(myCustomVPName) {
  if (browserWindow.ADRUM) {
    browserWindow.ADRUM.command('setVirtualPageName', myCustomVPName);
    return success('Custom virtual page name is set');
  }
  return error("'adrum' is not initialized");
}

// Marking the beginning of the virtual page and waiting for the end to be manually marked.
export function startVirtualPageMonitoring(myCustomVPName) {
  if (browserWindow.ADRUM) {
    browserWindow.ADRUM.markVirtualPageBegin(myCustomVPName, true);
    return success('Virtual Page Begin marked');
  }
  return error("'adrum' is not initialized");
}

// Marking the beginning of the virtual page and allowing the JS Agent to mark the end of the virtual page.
export function startVirtualPageMonitoringWithAutoEnd(myCustomVPName) {
  if (browserWindow.ADRUM) {
    browserWindow.ADRUM.markVirtualPageBegin(myCustomVPName, false);
    return success('Virtual Page Begin marked');
  }
  return error("'adrum' is not initialized");
}

// Manually marking the end of the virtual page.
export function endVirtualPageMonitoring() {
  if (browserWindow.ADRUM) {
    browserWindow.ADRUM.markVirtualPageEnd();
    return success('Virtual Page End marked');
  }
  return error("'adrum' is not initialized");
}

export function setXHRPayloadParams(payloadParams) {
  if (payloadParams) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || {};
    xhr.payloadParams = payloadParams;
    config.xhr = xhr;
    window['adrum-config'] = config;
    return success('XHR payload params are set');
  }
  return error('Please provide valid XHR payload params');
}

// To Set Ajax Request Names Based on Captured POST Parameters
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Ajax+Request+Names+Based+on+Captured+POST+Parameters
export function captureApiPostParameters(url, getFromBodyCB) {
  if (url && url.pattern) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || {};
    xhr.parameter = {};
    xhr.parameter.url = url;
    if (typeof getFromBodyCB === 'function') {
      xhr.parameter.getFromBody = getFromBodyCB;
    }
    config.xhr = xhr;
    window['adrum-config'] = config;
    return success('Capture Api Post Parameters are set');
  }
  return error('Please provide valid url pattern');
}

// To Set the Maximum Number of Segments
// Ref => https://docs.appdynamics.com/display/PRO45/Configure+the+Number+and+Length+of+URL+Segments
export function setMaxResUrlSegmentNumber(segmentNumber) {
  if (typeof segmentNumber === 'number') {
    const config = window['adrum-config'] || {};
    config.maxResUrlSegmentNumber = segmentNumber;
    window['adrum-config'] = config;
    return success('Maximum Number of Segments are set');
  }
  return error('Please provide valid Segment Number');
}

export function setSPA2() {
  const config = window['adrum-config'] || {};
  const spa = config.spa || {};
  spa.spa2 = true;
  config.spa = spa;
  window['adrum-config'] = config;
  return success('SPA2 is set');
}

// To Hide All or Parts of the URL Query String
// Ref => https://docs.appdynamics.com/display/PRO45/Hide+All+or+Parts+of+the+URL+Query+String
// filterURLQuery can be false(by default), true or array of keys
export function filterURLQueryString(filterURLQuery) {
  if (filterURLQuery) {
    const config = window['adrum-config'] || {};
    const urlCapture = config.urlCapture || {};
    urlCapture.filterURLQuery = filterURLQuery;
    config.urlCapture = urlCapture;
    window['adrum-config'] = config;
    return success('Filter URL Query is set');
  }
  return error(
    'Please provide valid filter for query string; i.e true, false, or array of keys',
  );
}

// To Exclude Virtual Pages from Being Monitored
// Ref => https://docs.appdynamics.com/display/PRO45/Exclude+Virtual+Pages+from+Being+Monitored
// Param =>
// {
//  exclude: {
//   "urls": [
//            {"pattern": "contact"},
//            {"pattern": "api*"}
//            ]
//  }
// }
export function setVPageFilter(filterPattern) {
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config = window['adrum-config'] || {};
    const spa = config.spa || {};
    const spa2 = spa.spa2 || {};
    const vp = spa2.vp || {};
    if (filterPattern.include) {
      vp.include = filterPattern.include;
    }
    if (filterPattern.exclude) {
      vp.exclude = filterPattern.exclude;
    }
    spa2.vp = vp;
    spa.spa2 = spa2;
    config.spa = spa;
    window['adrum-config'] = config;
    return success(
      'Include\\Exclude virtual page filter patterns added successfully',
    );
  }
  return error('Virtual Page Include\\Exclude filter patterns are not valid');
}

// Exclude Ajax from VPageView using ADRUM configuration
/*
The general structure for the filter object
params => filterPattern
For ex.
{
  include : {
      urls:
        [
          {
            pattern: ".*foo.*"
          },
          {
            pattern: ".*bar.*"
          }
        ],
      method: 'GET'
  },
  exclude : {
      urls:
        [
          {
            pattern: ".*user-profile.*"
          }
        ],
      method: 'POST'
  }
}
*/
export function setVPFilter(filterPattern) {
  if (!isScriptLoaded()) {
    return error(
      'To use XHR filters for virtual page, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script',
    );
  }
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config = window['adrum-config'] || {};
    const spa = config.spa || {};
    const angular = {
      vp: {
        xhr: filterPattern,
      },
    };
    spa.angular = angular;
    config.spa = spa;
    window['adrum-config'] = config;
    return success(
      'Include\\Exclude XHR filter patterns for virtual page added successfully',
    );
  }
  return error(
    'XHR Include\\Exclude filter patterns for virtual page are not valid',
  );
}

function validateConfig(config) {
  if (
    typeof config === 'undefined' ||
    typeof (config || {}).appKey === 'undefined'
  ) {
    // eslint-disable-next-line no-console
    console.error(
      'window.emui.brumConfig.appKey is not set to a valid AppDynamics key',
    );
    return false;
  }
  return true;
}

function getDefaultConfig(adrumConfig) {
  const config = adrumConfig;
  const baseURI = document.baseURI || window.location.origin;
  // eslint-disable-next-line prefer-template
  const defaultAdrumExtUrlHttp = baseURI.replace(/\/$/, '') + '/js/vendor';
  config.beaconUrlHttp =
    config.beaconUrlHttp || 'http://pdx-col.eum-appdynamics.com';
  config.beaconUrlHttps =
    config.beaconUrlHttps || 'https://pdx-col.eum-appdynamics.com';

  config.adrumExtUrlHttp = config.adrumExtUrlHttp || defaultAdrumExtUrlHttp;
  config.adrumExtUrlHttps = config.adrumExtUrlHttps || defaultAdrumExtUrlHttp;

  config.xd = config.xd || { enable: true };
  config.spa = config.spa || { spa2: true };
  if (config.customData) {
    config.userEventInfo = {
      PageView: bindCustomData.bind(this, 'pageview', config.customData),
      VPageView: bindCustomData.bind(this, 'vpageview', config.customData),
      Ajax: bindCustomData.bind(this, 'ajax', config.customData),
    };
  }
  return config;
}

function init(config) {
  if (!validateConfig(config)) return;
  const adrumConfig = window['adrum-config'] || {};
  const { onLoad, ...rest } = config;
  window['adrum-config'] = Object.assign(
    adrumConfig,
    getDefaultConfig({ ...rest }),
  );
  window['adrum-app-key'] = config.appKey || '';
  window['adrum-start-time'] = new Date().getTime();
}

init((window.emui || {}).brumConfig || {});

function getBrowserWindow() {
  try {
    window.top.emui = window.top.emui || {};
    return window.top;
  } catch (err) {
    return window;
  }
}

export default getBrowserWindow;
